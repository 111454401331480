<template>
  <div class="list">
    <template v-if="list && list.length">
      <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
      >
        <div class="content">
          <div class="image-box">
            <img
                class="image"
                :src="item.cutFrontImage || item.abbreviationFrontImage"
                @click="previewImg(item)"
            />
            <div class="tip">{{item.id}}</div>
          </div>
          <div class="name">{{item.coinKindItemName + '·' + item.coinKindItemVersionName}}</div>
        </div>
        <div class="footer"></div>
      </div>
    </template>
    <a-empty v-else :description="false" class="null" />
  </div>
</template>

<script>
export default {
  props: {
    list: {
      default: [],
    },
  },
  methods: {
    /** 预览 */
    previewImg(item) {
      this.$previewImg({
        list: [{img_url: item.cutFrontImage || item.abbreviationFrontImage}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      margin-top: 10px;
      .image-box {
        position: relative;
        .image {
          width: 300px;
          height: 300px;
          border-radius: 4px;
        }
        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 80px;
          height: 20px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
      }
      .name {
        text-align: center;
        margin-top: 2px;
        font-weight: bold;
      }
    }
    .footer {
      margin-top: 2px;
    }
  }
}
.null {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>