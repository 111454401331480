import instance from "@/axios"

/** 获取开元通宝正面面文列表 */
export const getKaiYuanCoinFrontTextListDataByQueryParamsApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanKaiyuanFaceRel/searchFaceVersion',
        method: 'GET',
        params: params
    })
}

/** 获取开元通宝正面面文列表数据 */
export const getKaiYuanCoinFrontTextListDataApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanKaiyuanSpecialFaceData/list',
        method: 'GET',
        params: params
    })
}

/** 面文底库数据同步 */
export const synchronizeDataByKaiYuanCoinFrontData = async () => {
    return instance({
        url: '/dq_admin/duibanKaiyuanFaceRel/asyncConfirmDataToFaceData',
        method: 'GET',
    })
}