var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list" },
    [
      _vm.list && _vm.list.length
        ? _vm._l(_vm.list, function(item, index) {
            return _c("div", { key: index, staticClass: "item" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "image-box" }, [
                  _c("img", {
                    staticClass: "image",
                    attrs: {
                      src: item.cutFrontImage || item.abbreviationFrontImage
                    },
                    on: {
                      click: function($event) {
                        return _vm.previewImg(item)
                      }
                    }
                  }),
                  _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(item.id))])
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._v(
                    _vm._s(
                      item.coinKindItemName + "·" + item.coinKindItemVersionName
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "footer" })
            ])
          })
        : _c("a-empty", { staticClass: "null", attrs: { description: false } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }