
<template>
  <div class="content">
    <div class="top">
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          @search='getKaiYuanCoinFrontTextListDataByQueryParams'
          class="w-300"
          v-model="params.versionId"
          placeholder="请选择面文"
      >
        <a-select-option
            v-for="item of kaiYuanByFrontQueryParamsList"
            :key="item.id"
            :value="item.id"
        >{{ item.coinKindVersionName }}</a-select-option>
      </a-select>
      <a-button
          @click="search"
          type="primary"
          class="ml-20"
      >搜索</a-button>
      <a-button
          @click="handleSynchronizeData"
          class="ml-10"
      >面纹标库数据同步</a-button>
    </div>
    <div class="table-content">
      <TableList :list="listData"/>
      <div class="pagination">
        <a-pagination
            :pageSize.sync="pagination.pageSize"
            :current="pagination.current"
            :total="pagination.total"
            @change="handleChangePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import untils from "@/untils";
import {
  getKaiYuanCoinFrontTextListDataApi,
  getKaiYuanCoinFrontTextListDataByQueryParamsApi, synchronizeDataByKaiYuanCoinFrontData
} from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontDataManage/_apis"
import TableList from "@/views/cmsPage/versionManage/kaiYuanCoinByFrontDataManage/_components/TableList"

export default {
  components: {
    TableList
  },
  data() {
    return {
      untils,
      listData: [],
      kaiYuanByFrontQueryParamsList: [],
      params: {
        pageSize: 30,
        pageNum: 1,
        versionId: undefined,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showTotal: (total) => `共${total}条`,
      },
      isSyncLadingStatus: false,
    }
  },
  async mounted() {
    await this.getKaiYuanCoinFrontTextListDataByQueryParams()
    await this.getList()
  },
  methods: {
    /** 同步底库面文数据 */
    async handleSynchronizeData() {
      if (this.isSyncLadingStatus) return this.$message.warn('正在同步，请勿重复操作...')
      this.$loading.show()
      this.isSyncLadingStatus = true
      const res = await synchronizeDataByKaiYuanCoinFrontData()
      this.$loading.hide()
      this.isSyncLadingStatus = false
      if (res.status !== '200') return
      await this.getList()
    },
    /** 获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getKaiYuanCoinFrontTextListDataApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.listData = res.data.records
      this.pagination.total = res.data.total
    },
    /** 翻页 */
    handleChangePage(current) {
      this.$set(this.pagination, "current", current)
      this.$set(this.params, "pageNum", current)
      this.getList()
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1)
      this.$set(this.params, "pageNum", 1)
      this.getList()
    },
    /** 获取正面面文列表 */
    async getKaiYuanCoinFrontTextListDataByQueryParams(inner) {
      const res = await getKaiYuanCoinFrontTextListDataByQueryParamsApi({
        versionName: inner,
        pageNum: 1,
        pageSize: 10
      })
      if (res.status !== '200') return
      this.kaiYuanByFrontQueryParamsList = res.data.records
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
